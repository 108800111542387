










import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import BaseTextArea from '@/general/components/general/BaseTextArea.vue'
import BaseForm from '@/general/components/general/BaseForm.vue'
import Popup from '@/general/components/common/Popup.vue'
import FormMixin from '@/general/mixins/FormMixin'
import {AccessCodeNotificationCreate, NotificationMedium} from "@/generated/globalTypes";
import PlanningAccessCodeService from "@/modules/planning/PlanningAccessCodeService";
import {GetAbout_GetAbout} from "@/generated/GetAbout";
import InfoCard from "@/general/components/common/InfoCard.vue";
@Component({
  components: {InfoCard, BaseForm, Popup, BaseTextArea, BaseTextField }
})
export default class AboutPopup extends Mixins(FormMixin, PlanningAccessCodeService) {
  @Prop() value: boolean
  @Prop() about: GetAbout_GetAbout[]
}
