





































import {Component, Mixins, Vue} from 'vue-property-decorator'
import { NewsMessageInstance } from '@/modules/messages/models/Message'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import AuthenticationService from "@/general/services/AuthenticationService";
import AboutService from "@/modules/about/AboutService";

@Component({
  components: {
    BaseDataTable
  }
})
export default class TopNav extends Mixins(AuthenticationService, AboutService) {
  notifications: NewsMessageInstance[] = []
  newMessageCount: number = 0

  created() {
    this.$websocket.eventEmitter.on('news', this.subscription)
  }

  destroyed() {
    this.$websocket.eventEmitter.removeListener('news', this.subscription)
  }

  resetNewMessageCount() {
    this.newMessageCount = 0
    this.setTitle(this.newMessageCount)
  }

  subscription(triple: any) {
    const notification: NewsMessageInstance = {
      notification: {
        date: new Date(),
        message: triple.third,
        url: `/${triple.first.toLowerCase()}s/${triple.first.toLowerCase()}/${triple.second}`,
        company: null,
        location: null,
        appUser: null
      },
      id: Date.now().toString()
    }
    notification.notification.date = new Date()
    this.notifications.push(notification)
    this.newMessageCount++
    this.setTitle(this.newMessageCount)
  }
}
