












import { Component, Mixins, Prop } from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService'
import SurePopup from "@/general/components/common/SurePopup.vue";

@Component({
  components: {SurePopup}
})
export default class SideNavFooter extends Mixins(AuthenticationService) {
  @Prop() mini!: boolean
  surePopup = false


}
