









import { Component, Mixins } from 'vue-property-decorator'
import AuthenticationService from '@/general/services/AuthenticationService.ts'
import SideNav from '@/general/components/layouts/SideNav.vue'
import FirstLogin from '@/general/components/layouts/FirstLogin.vue'
import TopNav from '@/general/components/layouts/TopNav.vue'
import RouterViewWithError from '@/general/components/layouts/RouterViewWithError.vue'

@Component({
  components: {
    SideNav,
    TopNav,
    RouterViewWithError,
    FirstLogin
  }
})
export default class Desktop extends Mixins(AuthenticationService) {
  get storeUser() {
    return this.$store.state.user
  }

  created() {
    this.checkLogin(false)
  }
}
