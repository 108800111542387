









































































import { Component, Mixins } from 'vue-property-decorator'
import SideNavFooter from '@/general/components/layouts/SideNavFooter.vue'
import LanguageMenu from "@/general/components/layouts/LanguageMenu.vue";
import BaseSelect from '@/general/components/general/BaseSelect.vue'
import BaseAutocomplete from '@/general/components/general/BaseAutocomplete.vue'
import AuthenticationService from '@/general/services/AuthenticationService'
import Logo from '@/assets/images/logo.png'
import AboutPopup from "@/general/components/common/AboutPopup.vue";

@Component({
  components: {
    AboutPopup,
    BaseAutocomplete,
    BaseSelect,
    SideNavFooter,
    LanguageMenu
  }
})
export default class SideNav extends Mixins(AuthenticationService) {
  drawer = true
  fixed = false
  miniVariant = false
  logo = '/assets/img/logo.png'

  openTabs: string[] = []
  showAbout: boolean = false

  created() {
    this.toggleAll()
  }

  imgError() {
    console.log('Using fallback image')
    this.logo = Logo
  }

  toggleTab(tab: string) {
    const index = this.openTabs.indexOf(tab)
    if (index >= 0) {
      this.openTabs.splice(index, 1)
    } else {
      this.openTabs.push(tab)
    }
  }

  toggleAll() {
    Object.keys(this.getMenuItems()).forEach((index: string) => {
      this.toggleTab(index)
    })
  }

  toggleSidebar() {
    this.miniVariant = !this.miniVariant
    this.openTabs = []
    this.toggleAll()
  }

  getMenuItems(): any {
    return {
      'nav.planning': [
        {
          title: 'nav.current',
          icon: 'access_time',
          to: { name: 'planning-overview', query: { finished: false } },
          roles: ['TRANSPORT']
        },
        {
          title: 'nav.finished',
          icon: 'check_circle',
          to: { name: 'planning-overview', query: { finished: true } },
          roles: ['TRANSPORT']
        }
      ],
      'nav.shipments': [
        {
          title: 'nav.current',
          icon: 'access_time',
          to: { name: 'shipment-overview', query: { finished: false } },
          roles: ['SHIPMENT']
        },
        {
          title: 'nav.finished',
          icon: 'check_circle',
          to: { name: 'shipment-overview', query: { finished: true } },
          roles: ['TRANSPORT']
        },
        {
          title: 'ecmr.single',
          icon: 'assignment',
          to: { name: 'ecmr-overview' },
          roles: ['SHIPMENT']
        }
      ],
      // 'common.tradelane': [
      //   {
      //     title: 'nav.overview',
      //     icon: 'public',
      //     to: { name: 'tradelane-overview' },
      //     roles: []
      //   }
      // ],
      'nav.messages': [
        {
          title: 'nav.messages',
          icon: 'message',
          to: { name: 'messages' },
          roles: ['ADMIN']
        },
        {
          title: 'nav.report',
          icon: 'map',
          to: { name: 'report' },
          roles: ['ADMIN']
        }
      ],
      // 'common.create': [
      //   {
      //     title: 'nav.planning',
      //     icon: 'create',
      //     to: { name: 'planning-create' },
      //     roles: []
      //   },
      //   {
      //     title: 'nav.shipment',
      //     icon: 'assignment',
      //     to: { name: 'shipment-create' },
      //     roles: []
      //   },
      //   {
      //     title: 'common.tradelane',
      //     icon: 'public',
      //     to: { name: 'tradelane-create' },
      //     roles: []
      //   }
      // ],
      'company.single': [
        // {
        //   title: 'company.single',
        //   icon: 'business',
        //   to: { name: 'company-edit', params: { company: this.$store.state.user.company.id } },
        //   roles: [],
        //   hideAdmin: true
        // },
        {
          title: 'user.plural',
          icon: 'group',
          to: { name: 'user-overview' },
          roles: ['USER']
        },
        // {
        //   title: 'Report',
        //   icon: 'map',
        //   to: { name: 'map' },
        //   roles: []
        // },
        // {
        //   title: 'common.contacts',
        //   icon: 'phone',
        //   to: { name: 'contact-overview' },
        //   roles: ['COMPANY']
        // }
      ],
      'nav.settings': [
        // {
        //   title: 'erp.single',
        //   icon: 'developer_board',
        //   to: { name: 'erp-contact-overview' },
        //   roles: []
        // },
        // {
        //   title: 'wallet.single',
        //   icon: 'payment',
        //   to: { name: 'wallet' },
        //   roles: []
        // }
      ]
    }
  }
}
